<template>
  <div class="select-brand">
    <van-index-bar :index-list="indexList" highlight-color="blue">
      <template v-for="(value, key) in resultData">
        <van-index-anchor
          :key="key"
          style="background:#f7f8fa;font-weight: 700;color: rgb(57, 169, 237);"
          class="test"
          >{{ key }}</van-index-anchor
        >
        <van-cell
          v-for="itm in value"
          :key="itm"
          @click="() => selectedBrand(itm)"
          class="cars-item"
          title-class="cars-name"
        >
          <!-- <template #icon>
            <img
              src="../assets/image/carsIcon/Audi.png"
              class="cars-icon"
              alt="Image"
            />
          </template> -->
          <template #title>{{ itm.name }}</template>
        </van-cell>
      </template>
    </van-index-bar>
  </div>
</template>

<script>
import pinyin from "wl-pinyin";
import { cars, indexs } from "@/constant";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      indexList: indexs,
      resultData: {},
      onlyShowHave: true, // 只展示有数据的
    };
  },
  created() {
  },
  mounted() {
    this.handleData();
  },
  computed: {},
  watch: {},
  methods: {
    handleData() {
      const keys = Object.keys(cars);
      const values = Object.values(cars);
      const result = {};
      indexs.forEach((letter) => {
        result[letter] = [];
        values.forEach((item) => {
          const curLetter = pinyin.getFirstLetter(item.name).substring(0, 1);
          if (curLetter === letter) {
            result[letter].push(item);
          }
        });
        if (this.onlyShowHave && result[letter].length === 0) {
          // 只展示有数据的，删除没数据的
          delete result[letter];
        }
      });
      this.indexList = Object.keys(result);
      this.resultData = { ...result };
      this.$forceUpdate();
    },

    selectedBrand(value) {
      this.$router.push({
        path: `/secondHandCar?key=${value.name}`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.select-brand {
  position: relative;
  .test {
    .van-index-anchor {
      color: rgb(57, 169, 237);
    }
  }
  .van-index-bar__index--active {
    color: #000;
  }
  .cars-item {
    display: flex;
    .cars-name {
      display: flex;
      align-items: center;
    }
    .cars-icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }
}
::deep .van-index-anchor--sticky{
  color: rgb(57, 169, 237);
}
</style>
