const cars = {
  Audi: {
    key:'Audi',
    url: '',
    name: '奥迪'
  },
  BMW: {
    key:'BMW',
    url: '',
    name: '宝马'
  },
  MercedesBenz: {
    key:'MercedesBenz',
    url: '',
    name: '奔驰'
  },
  Volkswagen: {
    key:'Volkswagen',
    url: '',
    name: '大众'
  },
  Toyota: {
    key:'Toyota',
    url: '',
    name: '丰田'
  },
  Honda: {
    key:'Honda',
    url: '',
    name: '本田'
  },
  Nissan: {
    key:'Nissan',
    url: '',
    name: '日产'
  },
  Ford: {
    key:'Ford',
    url: '',
    name: '福特'
  },
  Lexus: {
    key:'Lexus',
    url: '',
    name: '雷克萨斯'
  },
  Jaguar: {
    key:'Jaguar',
    url: '',
    name: '捷豹'
  },
  Lamborghini: {
    key:'Lamborghini',
    url: '',
    name: '兰博基尼'
  },
  Ferrari: {
    key:'Ferrari',
    url: '',
    name: '法拉利'
  },
  Chrysler: {
    key:'Chrysler',
    url: '',
    name: '克莱斯勒'
  },
  Chevrolet: {
    key:'Chevrolet',
    url: '',
    name: '雪佛兰'
  },
  Renault: {
    key:'Renault',
    url: '',
    name: '雷诺'
  },
  Cadillac: {
    key:'Cadillac',
    url: '',
    name: '凯迪拉克'
  },
  Subaru: {
    key:'Subaru',
    url: '',
    name: '斯巴鲁'
  },
  AstonMartin: {
    key:'AstonMartin',
    url: '',
    name: '阿斯顿马丁'
  },
  Jaguar: {
    key:'Jaguar',
    url: '',
    name: '捷豹'
  },
  Volvo: {
    key:'Volvo',
    url: '',
    name: '沃尔沃'
  },
  Citroën: {
    key:'Citroën',
    url: '',
    name: '雪铁龙'
  },

}

export default cars